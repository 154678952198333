.accessibility-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;
}

.accessibility-modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 500px;
    position: relative;
    overflow-y: auto; /* Allows scrolling within the modal content itself */
}

.ally-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
}

.ally-button {
    border: none;
    background-color: transparent;
    color: white;
}

.ally-statement p {
    margin-bottom: 20px; /* Space below <h1> */

}
@media (max-width: 768px) {

    .accessibility-modal-overlay {

    }

    .accessibility-modal-content {
        width: 90%; /* Adjust width to take more space on smaller screens */
        height: 50%;
    }
}
.business-hours-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    margin: 0;

    background: linear-gradient(to right, rgba(0,0,0,.3), rgba(0,0,0,.3)), url(../../images/corn-field.jpeg);
    background-position: center;
    background-size: cover;
}

.hours-card {
    background: #fcebd1;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    width: 300px;
}

.card h2 {
    text-align: center;
    color: #333;
}

.card ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.card li {
    padding: 8px;
    border-bottom: 1px solid #ccc;
}

.card li:last-child {
    border-bottom: none;
}

@media (max-width: 468px) {
    .business-hours-container  {
        flex-direction: column;
    }

    .hours-card{
        width: 80%;
    }

}
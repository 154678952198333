.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.carousel {
    display: flex; /* Display items in a row */
    overflow-x: auto; /* Enable horizontal scrolling */
    align-items: center;
    width: 95%;
    scrollbar-width: thin; /* Optional: make scrollbar thinner for aesthetics */
    -webkit-overflow-scrolling: touch; /* Improves scrolling on touch devices */
    padding-left: 10px; /* Adjust based on your needs */
    scroll-padding-left: 10px; /* Ensure there's space on the left inside the scroll area */
}

.carousel::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.carousel-item {
    flex: 0 0 auto; /* Prevent buttons from stretching. Adjust size as needed */
    margin: 5px; /* Adjust spacing between buttons as needed */
    padding: 10px;
    margin-right: 5px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 1.15rem;
}

.carousel-item.active {
    /* background-color: darkgray; */
    /* text-decoration: underline;
    text-decoration-color: red; */
    border-bottom: 2.5px solid red;
}

.cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: calc(33.333% - 20px);
    max-width: 95%;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

.name-price {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 468px) {
    .cards-container {
        flex-direction: column;
    }

    .card {
        width: 100%;
    }

    .carousel {
        width: 95%;
    }
}
.aboutUsContainer {
    padding: 20px;
    color: white;
    background: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,.3)), url(../../images/grandmas-place.jpg);
    background-position: center;
    background-size: cover;

    height: 80vh;

    display: flex;            /* Enables Flexbox */
    flex-direction: column;   /* Stacks children vertically */
    justify-content: center;  /* Centers content vertically */
    align-items: center;     
}

.aboutUsContainer h1 {
    text-align: center;
}

.aboutUsContainer h2 {
    margin-top: 20px;
    font-weight: bold;
    text-align: left;
}

.aboutUsContainer p {
    line-height: 1.5;
    text-align: justify;
}

@media (max-width: 768px) {
    .aboutUsContainer {
        padding: 15px;
    }

    .aboutUsContainer h1, .aboutUsContainer h2 {
        text-align: center;
    }

    .aboutUsContainer p {
        text-align: left;
    }
}